
import {
    defineComponent,
    inject,
    ref,
    onMounted,
    reactive,
    toRefs,
    nextTick,
} from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { message } from 'ant-design-vue';
import { getPayoutBreakdown } from '@/API/salesReporting/claimCheckingReport';
export default defineComponent({
    setup() {
        const echarts: any = inject('ec');
        const myChart = ref<any>({});
        const myChart2 = ref<any>({});
        const searchData = reactive({
            bu: '',
            brand: '',
            year: '',
            quarter: '1',
        });
        const yearArray: Array<string> = [];
        for (let i = 5; i >= 0; i--) {
            yearArray.push(
                moment()
                    .subtract(i, 'year')
                    .format('YYYY')
            );
        }
        for (let i = 1; i <= 5; i++) {
            yearArray.push(
                moment()
                    .add(i, 'year')
                    .format('YYYY')
            );
        }
        const buList = ref<any[]>([]);
        const brandList = ref<any[]>([]);
        const brandArray = ref<any[]>([]);

        const genMonth = (
            year: string,
            param: Array<string> | string | undefined
        ) => {
            let qmList: string[] = [];
            if (typeof param === 'undefined') {
                return undefined;
            } else if (typeof param === 'string') {
                qmList.push(param);
            } else {
                qmList = param;
            }
            const maps = {
                1: ['01', '02', '03'],
                2: ['04', '05', '06'],
                3: ['07', '08', '09'],
                4: ['10', '11', '12'],
            };
            let mthList: string[] = [];
            for (const qm of qmList as string[]) {
                if (qm in maps) {
                    mthList = [...mthList, ...maps[qm]];
                } else {
                    mthList.push(qm);
                }
            }
            const monthList = mthList.map((mth: any) => {
                return `${year}-${mth}`;
            });
            return monthList.length > 0 ? monthList : undefined;
        };

        const getParams = () => {
            let buName = '';
            for (let i = 0; i < buList.value.length; i++) {
                if (searchData.bu === buList.value[i].id) {
                    buName = buList.value[i].nameEn;
                }
            }

            const params = {
                bu: [buName],
                groupType: 1,
                payoutRoundYear: searchData.year,
                payoutRoundMonth: genMonth(searchData.year, searchData.quarter),
                brand: searchData.brand === 'All' ? '' : searchData.brand,
                language: 'EN',
            };
            return params;
        };

        const queryData = () => {
            //
            if (!searchData.bu || !searchData.year) {
                message.error('Year and BU must be selected!');
                return;
            }

            const params = getParams();
            getPayoutBreakdown(params).then((res) => {
                const data1 = [];
                const data21 = [];
                const data22 = [];
                const xAxisData = [];
                const excludedGroupName = [
                    '',
                    'Total',
                    'Order Protection',
                    'Pre Sales',
                ];
                for (let i = 0; i < (res.length > 10 ? 10 : res.length); i++) {
                    if (excludedGroupName.includes(res[i].groupName)) {
                        break;
                    }
                    data1.push({
                        name: res[i].groupName,
                        value: res[i].totalClaims,
                    });
                    data21.push((res[i].firstApproved * 100).toFixed(2));
                    data22.push((res[i].finalApproved * 100).toFixed(2));
                    xAxisData.push(res[i].groupName);
                }

                const unwarp = (obj: any) =>
                    obj && (obj.__v_raw || obj.valueOf() || obj);

                unwarp(myChart.value).setOption({
                    tooltip: {
                        show: true,
                    },
                    series: [
                        {
                            type: 'treemap',
                            data: data1,
                            label: {
                                position: 'insideTopLeft',
                                fontSize: 18,
                                formatter: function(params: any) {
                                    const arr = [params.name, '', params.value];
                                    // const arr = [
                                    //     '{name|' + params.name + '}',
                                    //     '{hr| }',
                                    //     '{budget|$' + params.value + '}',
                                    // ];

                                    return arr.join('\n');
                                },
                            },
                            rich: {
                                budget: {
                                    fontSize: 22,
                                    lineHeight: 30,
                                    color: 'yellow',
                                },
                                household: {
                                    fontSize: 14,
                                    color: '#fff',
                                },
                                label: {
                                    fontSize: 9,
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                    color: '#fff',
                                    borderRadius: 2,
                                    padding: [2, 4],
                                    lineHeight: 25,
                                    align: 'right',
                                },
                                name: {
                                    fontSize: 18,
                                    color: '#fff',
                                },
                                hr: {
                                    width: '100%',
                                    borderColor: 'rgba(255,255,255,0.2)',
                                    borderWidth: 0.5,
                                    height: 0,
                                    lineHeight: 10,
                                },
                            },
                            roam: false,
                            breadcrumb: {
                                show: false,
                            },
                            levels: [
                                {
                                    itemStyle: {
                                        borderWidth: 0,
                                        gapWidth: 5,
                                    },
                                },
                                {
                                    itemStyle: {
                                        gapWidth: 1,
                                    },
                                },
                                {
                                    colorSaturation: [0.35, 0.5],
                                    itemStyle: {
                                        gapWidth: 1,
                                        borderColorSaturation: 0.6,
                                    },
                                },
                            ],
                            nodeClick: false,
                        },
                    ],
                });

                unwarp(myChart2.value).setOption({
                    legend: {},
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        axisLabel: {
                            interval: 0,
                            rotate: 15,
                            align: 'center',
                            margin: 35,
                        },
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: 'R1 Approved%',
                            data: data21,
                            type: 'bar',
                        },
                        {
                            name: 'Final Approved%',
                            data: data22,
                            type: 'bar',
                        },
                    ],
                });
            });
        };

        const getBrand = () => {
            searchData.brand = '';
            for (let i = 0; i < brandArray.value.length; i++) {
                if (searchData.bu === brandArray.value[i].buId) {
                    brandList.value = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }
            if (brandList.value.length) {
                brandList.value.push({ id: 0, brandNameEn: 'All' });
                searchData.brand = 'All';
            }
        };

        const init = (buArray: Array<string>, brandArrayData: Array<any>) => {
            buList.value = buArray;
            brandArray.value = brandArrayData;
            searchData.bu = (buArray[0] as any).id;
            for (let i = 0; i < buArray.length; i++) {
                if ((buArray[i] as any).nameEn === 'MB') {
                    searchData.bu = (buArray[i] as any).id;
                }
            }
            getBrand();
            searchData.year = moment().format('YYYY');
            searchData.quarter = moment().quarter() + '';

            queryData();
        };

        //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        const router = useRouter();
        const gotoDetail = () => {
            router.push({
                path: '/salesreporting/payoutBreakdownOEM',
                query: {
                    bu: searchData.bu,
                    brand: searchData.brand,
                    year: searchData.year,
                    quarter: searchData.quarter,
                },
            });
        };

        onMounted(() => {
            myChart.value = echarts.init(
                document.getElementById('CCO_1') as HTMLElement
            );
            myChart2.value = echarts.init(
                document.getElementById('CCO_2') as HTMLElement
            );
        });

        return {
            openProgramPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            ...toRefs(searchData),
            init,
            brandList,
            yearArray,
            buList,
            getBrand,
            queryData,
            gotoDetail,
        };
    },
});
