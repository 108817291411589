
interface TableDataType {
    rejectedType: string | null;
    rejectedTypeClaims: number | null;
    rejectedTypeProportion: number | null;
    rejectedTypeProportionStr: string | null;
    rejectedReason: string | null;
    rejectedReasonClaims: number | null;
    rejectedReasonProportion: number | null;
    rejectedReasonProportionStr: string | null;
}
import moment from 'moment';
import { message } from 'ant-design-vue';
import { getFailedReasonAnalysis } from '@/API/salesReporting';
import { defineComponent, inject, ref, reactive, toRefs } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    setup() {
        const echarts: any = inject('ec');
        const mergeIndexProxy = ref<number[]>([]);
        const mergeLengthIndexProxy = ref<number[]>([]);

        const renderContent = ({ text, index }: any) => {
            const obj = {
                children: text,
                props: {} as any,
            };
            if (mergeIndexProxy.value.length) {
                for (let i = 0; i < mergeIndexProxy.value.length; i++) {
                    if (index === mergeIndexProxy.value[i]) {
                        obj.props.rowSpan = mergeLengthIndexProxy.value[i] + 1;
                    }
                    if (
                        index > mergeIndexProxy.value[i] &&
                        index <=
                            mergeIndexProxy.value[i] +
                                mergeLengthIndexProxy.value[i]
                    ) {
                        obj.props.rowSpan = 0;
                    }
                }

                return obj;
            } else {
                return obj;
            }
        };
        const columns = ref<any>([]);
        const towColumns = {
            Closed: [
                {
                    title: 'Rejected Type',
                    dataIndex: 'rejectedType',
                    customRender: renderContent,
                    width: 200,
                },
                {
                    title: 'Number of Programs',
                    dataIndex: 'programNo',
                    customRender: renderContent,
                    width: 200,
                },
                {
                    title: 'Final Rejected Claims',
                    dataIndex: 'rejectedTypeClaims',
                    customRender: renderContent,
                    width: 200,
                },
                {
                    title: 'Proportion%',
                    dataIndex: 'rejectedTypeProportionStr',
                    customRender: renderContent,
                    width: 150,
                },
                {
                    title: 'Rejected Reason',
                    dataIndex: 'rejectedReason',
                },
                {
                    title: 'Final Rejected Claims',
                    dataIndex: 'rejectedReasonClaims',
                    width: 150,
                },
                {
                    title: 'Proportion%',
                    dataIndex: 'rejectedReasonProportionStr',
                    width: 150,
                },
            ],
            'Non-Closed': [
                {
                    title: 'Rejected Type',
                    dataIndex: 'rejectedType',
                    customRender: renderContent,
                    width: 200,
                },
                {
                    title: 'Number of Programs',
                    dataIndex: 'programNo',
                    customRender: renderContent,
                    width: 200,
                },
                {
                    title: 'Rejected Claims',
                    dataIndex: 'rejectedTypeClaims',
                    customRender: renderContent,
                    width: 150,
                },
                {
                    title: 'Proportion%',
                    dataIndex: 'rejectedTypeProportionStr',
                    customRender: renderContent,
                    width: 150,
                },
                {
                    title: 'Rejected Reason',
                    dataIndex: 'rejectedReason',
                },
                {
                    title: 'Rejected Claims',
                    dataIndex: 'rejectedReasonClaims',
                    width: 150,
                },
                {
                    title: 'Proportion%',
                    dataIndex: 'rejectedReasonProportionStr',
                    width: 150,
                },
            ],
        };
        const dataSource = ref<TableDataType[]>([]);
        const searchData = reactive({
            bu: '',
            brand: '',
            year: '',
            quarter: '1',
        });

        const yearArray: Array<string> = [];
        for (let i = 5; i >= 0; i--) {
            yearArray.push(moment().subtract(i, 'year').format('YYYY'));
        }
        for (let i = 1; i <= 5; i++) {
            yearArray.push(moment().add(i, 'year').format('YYYY'));
        }
        const buList = ref<any[]>([]);
        const brandList = ref<any[]>([]);
        const brandArray = ref<any[]>([]);
        const myChart1 = ref<any>({});

        const closedType = ref<string>('Closed');

        const genMonth = (year: string, quarter: string) => {
            let array: Array<string> = [];
            switch (quarter) {
                case '1':
                    array = ['01', '02', '03'];
                    break;
                case '2':
                    array = ['04', '05', '06'];
                    break;
                case '3':
                    array = ['07', '08', '09'];
                    break;
                case '4':
                    array = ['10', '11', '12'];
                    break;
            }
            return array.map((mon: string) => {
                return `${year}-${mon}`;
            });
        };

        const getParams = () => {
            let buName = '';
            for (let i = 0; i < buList.value.length; i++) {
                if (searchData.bu === buList.value[i].id) {
                    buName = buList.value[i].nameEn;
                }
            }
            const params = {
                bu: [buName],
                groupType: 1,
                payoutRoundYear: searchData.year,
                payoutRoundMonth: genMonth(searchData.year, searchData.quarter),
                // programPeriod: searchData.year + '-' + searchData.quarter,
                language: 'EN',
            };
            return params;
        };
        const queryData = () => {
            if (!searchData.bu || !searchData.year) {
                message.error('Year and BU must be selected!');
                return;
            }

            const params = getParams();
            getFailedReasonAnalysis(params).then((res) => {
                dataSource.value = res[closedType.value];
                const data = res[closedType.value];
                columns.value = towColumns[closedType.value];

                const map = new Map();
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].rejectedReason !== 'Total' &&
                        data[i].rejectedReason !== 'Sub Total'
                    ) {
                        map.set(data[i].rejectedType, {
                            name: data[i].rejectedType,
                            value: data[i].rejectedTypeClaims,
                            children: [],
                        });
                    }
                }
                for (let i = 0; i < data.length; i++) {
                    if (
                        data[i].rejectedReason !== 'Total' &&
                        data[i].rejectedReason !== 'Sub Total'
                    ) {
                        map.get(data[i].rejectedType).children.push({
                            name: data[i].rejectedReason,
                            value: data[i].rejectedReasonClaims,
                        });
                    }
                }
                const dataArray: Array<any> = new Array<any>();
                for (const [key] of map) {
                    if (key !== null) {
                        dataArray.push(map.get(key));
                    }
                }

                const unwarp = (obj: any) =>
                    obj && (obj.__v_raw || obj.valueOf() || obj);
                unwarp(myChart1.value).setOption({
                    tooltip: {
                        show: true,
                    },
                    series: [
                        {
                            roam: false,
                            type: 'treemap',
                            data: dataArray,
                            breadcrumb: {
                                show: false,
                            },
                            label: {
                                formatter: '{b}',
                                fontSize: 14,
                            },
                            upperLabel: {
                                show: true,
                                height: 40,
                                color: '#fff',
                            },
                            levels: [
                                {
                                    itemStyle: {
                                        borderColor: '#777',
                                        borderWidth: 0,
                                        gapWidth: 1,
                                    },
                                    upperLabel: {
                                        show: false,
                                    },
                                },

                                {
                                    colorSaturation: [0.35, 0.5],
                                    itemStyle: {
                                        borderWidth: 5,
                                        gapWidth: 1,
                                        borderColorSaturation: 0.6,
                                    },
                                },
                            ],
                            nodeClick: false,
                        },
                    ],
                });

                const mergeIndex = []; //需要合并的起始角标
                const mergeLengthIndex = []; //起始角标下多少个
                let isMerge = false; //是否有合并了
                let isMergeNum = 0; //记录合并了几个
                for (let i = 0; i < data.length - 2; i++) {
                    const data1 = data[i];
                    const data2 = data[i + 1];
                    if (data1.rejectedType === data2.rejectedType && !isMerge) {
                        isMerge = true;
                        isMergeNum++;
                        mergeIndex.push(i);
                    }

                    if (data1.rejectedType !== data2.rejectedType && isMerge) {
                        mergeLengthIndex.push(isMergeNum);
                        isMergeNum = 0;
                        isMerge = false;
                    }
                    if (i === data.length - 3 && isMerge) {
                        mergeLengthIndex.push(isMergeNum);
                    }
                }
                mergeIndexProxy.value = mergeIndex;
                mergeLengthIndexProxy.value = mergeLengthIndex;
            });
        };

        const init = (buArray: Array<string>, brandArrayData: Array<any>) => {
            buList.value = buArray;
            brandArray.value = brandArrayData;
            searchData.bu = (buArray[0] as any).id;
            for (let i = 0; i < buArray.length; i++) {
                if ((buArray[i] as any).nameEn === 'MB') {
                    searchData.bu = (buArray[i] as any).id;
                }
            }
            for (let i = 0; i < brandArray.value.length; i++) {
                if (searchData.bu === brandArray.value[i].buId) {
                    brandList.value = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }
            if (brandList.value.length) {
                searchData.brand = brandList.value[0].brandNameEn;
            }
            searchData.year = moment().format('YYYY');
            searchData.quarter = moment().quarter() + '';
            myChart1.value = echarts.init(
                document.getElementById('failed-chart1') as HTMLElement
            );

            queryData();
        };

        const getBrand = () => {
            searchData.brand = '';
            for (let i = 0; i < brandArray.value.length; i++) {
                if (searchData.bu === brandArray.value[i].buId) {
                    brandList.value = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }
        };
        //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        const router = useRouter();
        const gotoDetail = () => {
            router.push({
                path: '/salesreporting/failedReasonAnalysis',
                query: {
                    bu: searchData.bu,
                    year: searchData.year,
                    quarter: searchData.quarter,
                },
            });
        };

        return {
            openProgramPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            ...toRefs(searchData),
            init,
            brandList,
            yearArray,
            buList,
            getBrand,
            queryData,
            columns,
            dataSource,
            closedType,
            gotoDetail,
        };
    },
});
