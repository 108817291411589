
import moment from 'moment';
import { message } from 'ant-design-vue';
import {
    defineComponent,
    inject,
    ref,
    onMounted,
    reactive,
    toRefs,
    computed,
    watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { getPayoutBreakdown2 } from '@/API/salesReporting/claimCheckingReport';
export default defineComponent({
    setup() {
        const echarts: any = inject('ec');
        const activeKey = ref('1');
        const payoutBreakdownTitle = computed(() => {
            const titleMap = {
                1: 'Region',
                2: 'Sub Region',
                3: 'Dealer Group',
                4: 'Dealer',
            };
            return `Payout Breakdown by ${titleMap[activeKey.value]}`;
        });
        const searchData = reactive<any>({
            bu: '',
            brand: null,
            year: '',
            quarter: '1',
        });
        const yearArray: Array<string> = [];
        for (let i = 5; i >= 0; i--) {
            yearArray.push(moment().subtract(i, 'year').format('YYYY'));
        }
        for (let i = 1; i <= 5; i++) {
            yearArray.push(moment().add(i, 'year').format('YYYY'));
        }
        const buList = ref<any[]>([]);
        const brandList = ref<any[]>([]);
        const brandArray = ref<any[]>([]);

        const myChart2 = ref<any>({});

        const dataSource = ref<any>([]);

        const columns = ref<any[]>([
            {
                title: 'Region',
                dataIndex: 'groupName',
                key: 'groupName',
            },
            {
                title: 'ToTal Checked No.',
                dataIndex: 'totalCheckedCount',
                key: 'totalCheckedCount',
            },
            {
                title: 'Final Approved%',
                dataIndex: 'finalApprovedStr',
                key: 'finalApprovedStr',
            },
            {
                title: 'ToTal Checked No.(ex.Financing)',
                dataIndex: 'lastQuarterTotalCheckedCount',
                key: 'lastQuarterTotalCheckedCount',
            },
            {
                title: 'Final Approved%(ex.Financing)',
                dataIndex: 'lastQuarterFinalApprovedStr',
                key: 'lastQuarterFinalApprovedStr',
                // sorter: (a: any, b: any) => a.lastQuarterFinalApprove - b.lastQuarterFinalApprove,
            },
        ]);
        watch(
            [() => searchData.year, () => searchData.quarter],
            ([newYear, newQuarter]) => {
                console.log(
                    parseInt(newYear as unknown as string) - 1,
                    newQuarter
                );
                const currentYear = newYear as unknown as string;
                const currentQuarter = newQuarter as unknown as string;
                const currentTitle = `${currentYear} Q${currentQuarter}`;
                let lastTitle: string;
                if (currentQuarter === '1') {
                    lastTitle = `${(parseInt(currentYear) - 1).toString()} Q4`;
                } else {
                    lastTitle = `${currentYear} Q${
                        parseInt(currentQuarter) - 1
                    }`;
                }
                columns.value[1].title = `${currentTitle} ToTal Checked No.`;
                columns.value[2].title = `${currentTitle} Final Approved%`;
                columns.value[3].title = `${lastTitle} ToTal Checked No.(ex.Financing)`;
                columns.value[4].title = `${lastTitle} Final Approved%(ex.Financing)`;
            }
        );
        const getParams = () => {
            let buName = '';
            for (let i = 0; i < buList.value.length; i++) {
                if (searchData.bu === buList.value[i].id) {
                    buName = buList.value[i].nameEn;
                }
            }
            const params = {
                bu: [buName],
                groupType: parseInt(activeKey.value) + 1,
                programPeriod: searchData.year + '-' + searchData.quarter,
                brand: searchData.brand === 'All' ? '' : searchData.brand,
            };
            return params;
        };

        const queryData = () => {
            if (!searchData.bu || !searchData.year) {
                message.error('Year and BU must be selected!');
                return;
            }

            const params = getParams();

            getPayoutBreakdown2(params).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    if (!res[i].finalApprovedStr) {
                        res[i].finalApprovedStr = '0%';
                    }
                }
                dataSource.value = res;
                const data21 = [];
                const data22 = [];
                const xAxisData = [];
                for (let i = 0; i < (res.length > 20 ? 20 : res.length); i++) {
                    if (res[i].groupName === 'Total') {
                        break;
                    }
                    data21.push((res[i].firstApproved * 100).toFixed(2));
                    data22.push((res[i].finalApproved * 100).toFixed(2));
                    xAxisData.push(res[i].groupName);
                }

                const unwarp = (obj: any) =>
                    obj && (obj.__v_raw || obj.valueOf() || obj);
                unwarp(myChart2.value).setOption({
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                    },
                    legend: {},
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        axisLabel: {
                            align: 'center',
                            margin: 35,
                            interval: 0,
                            rotate: 15,
                            width: 100,
                            overflow: 'truncate',
                            ellipsis: '...',
                        },
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            name: 'R1 Approved%',
                            data: data21,
                            type: 'bar',
                        },
                        {
                            name: 'Final Approved%',
                            data: data22,
                            type: 'bar',
                        },
                    ],
                });
            });
        };

        const getBrand = () => {
            searchData.brand = null;
            for (let i = 0; i < brandArray.value.length; i++) {
                if (searchData.bu === brandArray.value[i].buId) {
                    brandList.value = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }
            if (brandList.value.length) {
                brandList.value.push({ id: 0, brandNameEn: 'All' });
                searchData.brand = 'All';
            }
        };

        const init = (buArray: Array<string>, brandArrayData: Array<any>) => {
            buList.value = buArray;
            brandArray.value = brandArrayData;
            searchData.bu = (buArray[0] as any).id;
            for (let i = 0; i < buArray.length; i++) {
                if ((buArray[i] as any).nameEn === 'MB') {
                    searchData.bu = (buArray[i] as any).id;
                }
            }
            getBrand();
            searchData.year = moment().format('YYYY');
            searchData.quarter = moment().quarter() + '';

            queryData();
        };

        const changeTab = (index: string) => {
            activeKey.value = index;
            switch (parseInt(index)) {
                case 1:
                    columns.value[0].title = 'Region';
                    columns.value[4].sorter = null;
                    break;
                case 2:
                    columns.value[0].title = 'Sub Region';
                    columns.value[4].sorter = null;
                    break;
                case 3:
                    columns.value[0].title = 'Dealer Group';
                    columns.value[4].sorter = null;
                    break;
                case 4:
                    columns.value[0].title = 'Dealer';
                    columns.value[4].sorter = (a: any, b: any) => {
                        return (
                            a.finalApprovedStr.substring(
                                0,
                                a.finalApprovedStr.length - 1
                            ) -
                            b.finalApprovedStr.substring(
                                0,
                                b.finalApprovedStr.length - 1
                            )
                        );
                    };

                    break;
            }

            queryData();
        };

        onMounted(() => {
            myChart2.value = echarts.init(
                document.getElementById('patout-dealer-chart2') as HTMLElement
            );
        });

        //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        const router = useRouter();
        const gotoDetail = () => {
            router.push({
                path: '/salesreporting/payoutBreakdownOEM',
                query: {
                    bu: searchData.bu,
                    brand: searchData.brand,
                    year: searchData.year,
                    quarter: searchData.quarter,
                },
            });
        };
        const onChange = (pagination: any, filters: any[], sorter: any) => {
            console.log('params', pagination, filters, sorter);
        };

        return {
            onChange,
            changeTab,
            activeKey,
            openProgramPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            ...toRefs(searchData),
            columns,
            dataSource,
            init,
            brandList,
            yearArray,
            buList,
            getBrand,
            queryData,
            gotoDetail,
            payoutBreakdownTitle,
        };
    },
});
